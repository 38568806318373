import(/* webpackMode: "eager", webpackExports: ["Spinner","theme"] */ "__barrel_optimize__?names=Spinner,theme!=!/srv/app/.yarn/__virtual__/@primer-react-virtual-4233415131/0/cache/@primer-react-npm-37.14.0-8bf75291d2-8d955a500d.zip/node_modules/@primer/react/lib-esm/index.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-38fade07fd/0/cache/@blueprintjs-core-npm-5.17.5-0a14d5e13f-406d9c9b39.zip/node_modules/@blueprintjs/core/lib/css/blueprint.css");
;
import(/* webpackMode: "eager", webpackExports: ["BlueprintProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-38fade07fd/0/cache/@blueprintjs-core-npm-5.17.5-0a14d5e13f-406d9c9b39.zip/node_modules/@blueprintjs/core/lib/esm/context/blueprintProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["HotkeysContext","HotkeysProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-38fade07fd/0/cache/@blueprintjs-core-npm-5.17.5-0a14d5e13f-406d9c9b39.zip/node_modules/@blueprintjs/core/lib/esm/context/hotkeys/hotkeysProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["OverlaysContext","OverlaysProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-38fade07fd/0/cache/@blueprintjs-core-npm-5.17.5-0a14d5e13f-406d9c9b39.zip/node_modules/@blueprintjs/core/lib/esm/context/overlays/overlaysProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["PortalContext","PortalProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-38fade07fd/0/cache/@blueprintjs-core-npm-5.17.5-0a14d5e13f-406d9c9b39.zip/node_modules/@blueprintjs/core/lib/esm/context/portal/portalProvider.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/@blueprintjs-icons-virtual-06c308df53/0/cache/@blueprintjs-icons-npm-5.19.1-81ffc09e0e-4ff3a64943.zip/node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/base/size/size.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/size/border.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/size/size-coarse.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/size/size.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/typography/typography.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-dimmed.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-tritanopia.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/light-colorblind.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/light-tritanopia.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/app/BaseStylesWithColorMode.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/app/globals-post-blueprint.scss");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/base/typography/typography.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/size/breakpoints.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/size/size-fine.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/size/viewport.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-colorblind.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-high-contrast.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/light-high-contrast.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.4.0-7cdead3a8b-53d5c8c8e4.zip/node_modules/@primer/primitives/dist/css/functional/themes/light.css");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider","authUrlOrigin","authUrlPath"] */ "/srv/app/packages/web-ui/src/auth/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["FidantAPIClientProvider"] */ "/srv/app/packages/web-ui/src/backend/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/components/ColorModeToggleButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/srv/app/packages/web-ui/src/lib/primer/ThemeProvider.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/lib/registry.tsx");
